import SContainer from '../../components/ui/s-container'

import backIcon from '../../assets/images/icone_voltar_simples.svg'

export default {
  name: 'Privacy',
  props: {
    showBackBtn: {
      type: Boolean,
      default: true,
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.refPath = from.path
    })
  },

  components: {
    SContainer,
  },

  data: () => ({
    backIcon,
    refPath: null,
  }),

  computed: {
    back() {
      return this.refPath ? this.refPath : '/'
    },
  },
}
